<template>
  <div class="SinglePageList_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item v-for="item in allCategory" :key="item.id" :index="item.id" :value="item.id">{{item.name}}</el-menu-item>
        </el-menu>
        <div class="GlisCentent" @click="dialogVisible = true">内容管理</div>
      </div>
      <div class="headDetails">说明：各个站点导航图标</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入标题/简介">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="page_id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="title" label="标题"  align="center"></el-table-column>
        <el-table-column prop="intro" label="简介"  align="center"></el-table-column>
        <el-table-column prop="hits" label="浏览量"  align="center"></el-table-column>
        <el-table-column prop="type" label="跳转类型"  align="center">
          <template  #default="scope">
              <span>{{ {1:'普通',2:'链接'}[scope.row.type||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status_text" label="状态"  align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重"  align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>标题：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.title" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>简介：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.intro" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转链接：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.url" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO标题：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.seo_title" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO关键字：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.seo_keyword" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO描述：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.seo_description" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>路由别名：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.alias" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>浏览量：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.hits" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否启用">
                <el-option v-for="(item, index) in statisticsColumns.status" :key="index" :label="item" :value="index"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.type" placeholder="请选择类型">
                <el-option v-for="(item, key) in typeList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>内容</div>
            <div class="EditNewFormItemIpt">
              <div id="demo1"></div>
            </div>
          </el-form-item>

          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    

    <!--职称管理-->
    <el-dialog title="创建指南" v-model="dialogVisible" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Category"
        class="table"
        header-cell-class-name="table-header">
        <el-table-column label="导航类别" width="240" align="center">
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input v-model="scope.row.name" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column> 
        <el-table-column label="权重" width="140" align="center">
          <template #default="scope" >
              <div class="El_pops dasdasd">
                <el-input v-model="scope.row.weigh" placeholder="权重" style="text-align: center;"></el-input>
              </div>
          </template>
        </el-table-column> 
        <el-table-column label="是否启用" width="140"  align="center">
          <template #default="scope" >
            <el-switch v-model="scope.row.status" 
              :active-value = 1
              :inactive-value = 0
              active-color="#1890FF"
              inactive-color="#dcdfe6"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="guideDelete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" @click="categoryAdd()"><i class="el-icon-plus"></i>创建类别</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="Close()">取 消</el-button>
          <el-button type="primary" @click="guideAdd()" style="margin-right:24px">确 定</el-button>
        </div>
      </div>  
    </el-dialog>




  </div>
</template>

<script>
import axios from 'axios'
import wangEditor from 'wangeditor'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      statisticsColumns:{},
      allCategory:[], //分类
      Category : [],
      activeIndex:1,
      typeList:[
        '普通',
        '链接'
      ],
      total: 0,
      wangE:0,
      editorData:'',
      editor:'',
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      }, 

      // 列表数据
      Row: {
        category_id: "",
        type: "",
        title: "",
        intro: "",
        url: "",
        content:'',
        seo_title:'',
        seo_keyword:'',
        seo_description:'',
        alias:'',
        hits:'',
        status:'',
        weigh: "",
      },
      //搜索参数
      search: {
        keyword: "",
        category_id: "",
      },
      addPanel: false,
      editPanel: false,
      dialogVisible:false,
      editId: 0,
      titleMath:'',
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    that.moukuai()
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    
  },
  methods: {
    // 导航选择
    handleSelect_top (e) {
      var that = this
      that.activeIndex =  e
      that.loadTable()
    },
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/page/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: {
            category_id : that.activeIndex,
            keyword : that.search.keyword
         },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //获取统计列表
    statistics(){
        var that = this
        axios.get('/admin/general/page/statisticsColumns', {   // 站点列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');
          } else {
            that.statisticsColumns = res.data.data
          }
        })
    },
    //模块
    moukuai(){
        var that = this
        axios.get('/admin/general/page/allCategory', {   // 站点列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');
          } else {
            that.allCategory = res.data.data.categoryList
            that.activeIndex = that.allCategory[0].id
            var att = res.data.data.categoryList
            var acc = att.slice(0)
            that.Category = acc
          }
        })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          keyword: "",
          site_id: "",
        }
      }
      that.loadTable();
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/page/add',
          data: {
            row: {
                category_id : that.activeIndex ,
                type : that.Row.type ,
                title : that.Row.title ,
                intro : that.Row.intro ,
                url : that.Row.url ,
                content : that.editorData ,
                seo_title : that.Row.seo_title ,
                seo_keyword : that.Row.seo_keyword ,
                seo_description : that.Row.seo_description ,
                alias : that.Row.alias ,
                hits : that.Row.hits ,
                status : that.Row.status ,
                weigh : that.Row.weigh ,
            }
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        category_id: "",
        type: "",
        title: "",
        intro: "",
        url: "",
        content:'',
        seo_title:'',
        seo_keyword:'',
        seo_description:'',
        alias:'',
        hits:'',
        status:'',
        weigh: '',
      };
      if (that.wangE == 20) {
        that.editor.destroy();
      } else {
        that.wangE = 0
      }
      that.init() 
      that.statistics()
      
    },
    // 编辑
    BtnEdit() {
      var that = this
      axios({
        method: 'put',
        url: '/admin/general/page/edit',
        data: {
          id: that.editId,
          row:{
            category_id : that.activeIndex ,
            type : that.Row.type ,
            title : that.Row.title ,
            intro : that.Row.intro ,
            url : that.Row.url ,
            content : that.editorData ,
            seo_title : that.Row.seo_title ,
            seo_keyword : that.Row.seo_keyword ,
            seo_description : that.Row.seo_description ,
            alias : that.Row.alias ,
            hits : that.Row.hits ,
            status : that.Row.status ,
            weigh : that.Row.weigh ,
          }
        },
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editPanel = false
          that.loadTable();
        }
      })
    },
    //获取编辑内容
    HandleEdit( data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.page_id
      that.statistics()
      axios.get('/admin/general/page/read', {   // 详情
        params: {
          id: data.page_id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
            that.Row.category_id = res.data.data.row.category_id ,
            that.Row.type = parseInt(res.data.data.row.type) ,
            that.Row.title = res.data.data.row.title ,
            that.Row.intro = res.data.data.row.intro ,
            that.Row.url = res.data.data.row.url ,
            that.Row.seo_title = res.data.data.row.seo_title ,
            that.Row.seo_keyword = res.data.data.row.seo_keyword ,
            that.Row.seo_description = res.data.data.row.seo_description ,
            that.Row.alias = res.data.data.row.alias ,
            that.Row.hits = res.data.data.row.hits ,
            that.Row.status = res.data.data.row.status_text,
            that.Row.weigh = res.data.data.row.weigh ,
            that.editorData = res.data.data.row.content
            if (that.wangE == 20) {
              that.editor.destroy();
            } else {
              that.wangE = 0
            }
            that.init() 
        }
      })
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false;
      that.editorData = ''
    },
    //删除
    HandleDel( data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.page_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/general/page/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    init() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr
        const editor = new wangEditor(`#demo1`)
        Eat = editor
        that.wangE = 20
        // 配置 onchange 回调函数，将数据同步到 vue 中
        // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
            token:that.heads.token
        }
        editor.config.onchange = (newHtml) => {
          that.editorData = newHtml
        }
        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }

        editor.create()
        // 创建编辑器
        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
      },200)
    },
    //删除类别
    guideDelete(row) { 
      var that = this
      var ATT = that.Category
      let guideId = row.id
      if (row.type != 'default'){
        let index = ATT.findIndex(obj => obj.index  === guideId)
        ATT.splice(index, 1)
      }
      that.Category = ATT
    },
    //创建类别
    categoryAdd(){
        var that = this
        var ATT = that.Category
        var srt = {
          name: " 新加 ",
          status: 0,
          weigh: '',
          id:0,
        }
        ATT.push(srt)
        that.Category = ATT
    },
    //指南创建确定
    guideAdd(e){
        var that = this
        var ATT = that.Category
        axios({
          method: 'post',
          url: '/admin/general/page/editCategoryMore',
          data: {
            content: ATT
          },
        }).then(function (res) {
          that.dialogVisible = false
          that.moukuai()
          that.activeIndex = 1
          that.loadTable()
        })
    },
  }
}
</script>
<style scoped>
.SinglePageList .tab_Top .Glis {
  width: 81px;
  height: 27px;
  border: 1px solid #0081FF;
  border-radius: 100px;
  text-align: center;
  line-height: 27px;
  color: #0081FF;
  font-size: 14px;
  margin: 5px 0 0 40px;
  cursor: pointer;
} 
</style>